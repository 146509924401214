<template>
  <div class="home">
    <h1 class="title">asdasdsd</h1>
    <p>General overview of the fulfillments?</p>

    <div
      v-if="story"
      v-html="Storyblok.richTextResolver.render(story.content.body)"
    ></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import appSettings from "../variables.js";
// import Storyblok from "storyblok-js-client";
// const Api = new Storyblok();

const StoryblokClient = require("storyblok-js-client");

let client = new StoryblokClient({
  accessToken: "6abEF7grJOvJMvqyzSfM5Att"
});

export default {
  name: "home",
  components: {},
  data: function() {
    return {
      Storyblok: client,
      story: ""
    };
  },
  created() {
    this.loadPageContent();
  },
  methods: {
    loadPageContent() {
      var page = this.$route.params.id;
      client
        .get("cdn/stories/" + page, {
          version: "draft"
        })
        .then(res => {
          console.log(res.data.story);
          this.story = res.data.story;
        });
    }
  },
  computed: {
    // richtext() {
    //   return this.text ? Api.richTextResolver.render(this.text) : "";
    // }
  }
};
</script>
